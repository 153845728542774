import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Antivirus.css";

const Antivirus = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}
      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>Antivirus</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src="./assets/Antivirus/img_sm_1.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>TREND MICRO</Card.Title>
                <Card.Text className="cartText">
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                      Trend Micro leads the pack with unparalleled threat
                      detection and response. Trend's 100% detection coverage,
                      real-time telemetry, and global customer base protects
                      thousands of organisations worldwide.
                    </>
                  ) : (
                    " Trend Micro leads the pack with unparalleled threat detection and response. Trend's 100% detection coverage..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src="./assets/Antivirus/img_sm_4.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>Kaspersky</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card4"] ? (
                    <>
                      {" "}
                      Protect: Avoid breaches and business disruptions by
                      minimizing ransomware and other cyberattacks with
                      segmentation, full visibility, and coordinated security.
                      Consolidate and scale: Reduce costs by eliminating point
                      products and deliver security at hyperscale to keep up
                      with business growth.
                    </>
                  ) : (
                    <>
                      Protect: Avoid breaches and business disruptions by
                      minimizing ransomware and other cyberattacks with...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 3 */}
          <Col className=" col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5  packageCart card5">
              <Card.Img
                variant="top"
                src="./assets/Antivirus/img_sm_5.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>McAfee</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card5"] ? (
                    <>
                      {" "}
                      McAfee offers protection on various devices as well as
                      integrated protection that is managed from one console.
                      The same powerful anti-malware technology that McAfee uses
                      to protect its enterprise customers is also used in its
                      consumer security services.
                    </>
                  ) : (
                    <>
                      McAfee offers protection on various devices as well as
                      integrated protection that is managed from one console...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                 
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Antivirus;
