import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Services.css";

const Services = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}

      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>Services</h1>
            </div>
          </Col>
        </Row>
      </Container>
      {/* card 1 */}
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Services\adobe.jpg"
                className="cardImg"
                style={{ height: "240px" }}
              />
              <Card.Body>
                <Card.Text>
                  <h4>Adobe</h4>
                  {expandedCards["card1"] ? (
                    <>
                     
                    </>
                  ) : (
                    "  ..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card2">
              <Card.Img
                variant="top"
                src=".\assets\Services\autodesk.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>Autodesk</Card.Title>
                <Card.Text>
                  {expandedCards["card2"] ? (
                    <>
                      
                    </>
                  ) : (
                    <>
                      ...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
{/* card 3 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src=".\assets\Services\microsoft.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>Microsoft Licensing</Card.Title>
                <Card.Text>
                  {expandedCards["card4"] ? (
                    <>
                      {" "}
                      
                    </>
                  ) : (
                    <>
                      ...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card4")}
                >
                  {expandedCards["card4"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 cardThree card3">
              <Card.Img
                variant="top"
                src=".\assets\Services\team viewer.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>Ubiquiti Switches</Card.Title>
                <Card.Text>
                  {expandedCards["card3"] ? (
                    <>
                      
                    </>
                  ) : (
                    <>
                      ...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card3")}
                >
                  {expandedCards["card3"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Services;
