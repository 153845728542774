import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Switches.css";

const Switches = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}

      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>Switches</h1>
            </div>
          </Col>
        </Row>
      </Container>
      {/* card 1 */}
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src="./assets/Switches/img_sm_1.jpg"
                className="cardImg"
                style={{ height: "240px" }}
              />
              <Card.Body>
                <Card.Text>
                  <h4>Cisco Switches</h4>
                  {expandedCards["card1"] ? (
                    <>
                      Switches that provide simplified Enterprise solution,
                      automation, detailed analytics, and exceptional speed and
                      scale.
                    </>
                  ) : (
                    "  Switches that provide simplified Enterprise solution automation, detailed analytics, and exceptional speed..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card2">
              <Card.Img
                variant="top"
                src="./assets/Switches/img_sm_2.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>HPE ARUBA Switches</Card.Title>
                <Card.Text>
                  {expandedCards["card2"] ? (
                    <>
                      Simplify the complexities of deploying and managing modern
                      enterprise networks with AI-powered automation and
                      built-in security delivered by network switches that scale
                      from edge to the cloud.
                    </>
                  ) : (
                    <>
                      Simplify the complexities of deploying and managing modern
                      enterprise networks with AI-powered automation and...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
{/* card 3 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src="./assets/Switches/img_sm_4.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>D-link Switches</Card.Title>
                <Card.Text>
                  {expandedCards["card4"] ? (
                    <>
                      {" "}
                      Protect: Avoid breaches and business disruptions by
                      minimizing ransomware and other cyberattacks with
                      segmentation, full visibility, and coordinated security.
                      Consolidate and scale: Reduce costs by eliminating point
                      products and deliver security at hyperscale to keep up
                      with business growth.
                    </>
                  ) : (
                    <>
                      Protect: Avoid breaches and business disruptions by
                      minimizing ransomware and other cyberattacks with
                      segmentation...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card4")}
                >
                  {expandedCards["card4"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 cardThree card3">
              <Card.Img
                variant="top"
                src="./assets/Switches/img_sm_3.jpg"
                className="cardImg"
                style={{
                  height: "250px",
                  mixBlendMode: "multiply",
                  backgroundColor: "white",
                }}
              />
              <Card.Body>
                <Card.Title>Ubiquiti Switches</Card.Title>
                <Card.Text>
                  {expandedCards["card3"] ? (
                    <>
                      Ubiquiti Switch is a fully managed Gigabit switch,
                      delivering robust performance and intelligent switching
                      for your growing networks
                    </>
                  ) : (
                    <>
                      Ubiquiti Switch is a fully managed Gigabit switch,
                      delivering robust performance and intelligent switching
                      for your growing networks
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card3")}
                >
                  {expandedCards["card3"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Switches;
