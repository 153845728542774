import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./NetworkStorage.css";

const NetworkStorage = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}
      <Container>
        <Row>
          <Col>
            <div className="service">
              <p>Our Products</p>
              <h1>Network Storage</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card2">
              <Card.Img
                variant="top"
                src="./assets/NetworkStorage/2.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>NET-APP Storage</Card.Title>
                <Card.Text>
                  {expandedCards["card2"] ? (
                    <>
                      NetApp is the hybrid cloud leader who can help you reduce
                      complexities, costs, and security risks across your
                      on-premises and multicloud environments.
                    </>
                  ) : (
                    "NetApp is the hybrid cloud leader who can help you reduce complexities, costs, and security risks across... "
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src="./assets/NetworkStorage/4.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>HP Storage</Card.Title>
                <Card.Text>
                  {expandedCards["card4"] ? (
                    <>
                      Delivers simple, reliable, cost-efficient hybrid storage
                      for general-purpose workloads.
                    </>
                  ) : (
                    "Delivers simple, reliable, cost-efficient hybrid storage for general-purpose workloads."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card4")}
                >
                  {expandedCards["card4"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src=".\assets\NetworkStorage\8.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>ACER Storage</Card.Title>
                <Card.Text>
                  {expandedCards["card4"] ? (
                    <>
                      Provides direct-attach storage at a fraction of the cost
                      of high-end SAN or NAS solutions. Seamlessly integrates
                      with Acer Altos servers, the Altos is a perfect companion
                      that expands usable storage.
                    </>
                  ) : (
                    "Provides direct-attach storage at a fraction of the cost of high-end SAN or NAS solutions..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card4")}
                >
                  {expandedCards["card4"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NetworkStorage;
