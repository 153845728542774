import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Servers.css";

const Servers = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}

      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>Servers</h1>
            </div>
          </Col>
        </Row>
      </Container>
      {/* card 1 */}
      <Container>
        <Row>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card2">
              <Card.Img
                variant="top"
                src="./assets/Servers/2.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>LENOVO Servers</Card.Title>
                <Card.Text>
                  {expandedCards["card2"] ? (
                    <>
                      Lenovo servers deliver powerful performance and versatile
                      storage configurations i for SMBs to large institutions.
                      Get best in class reliability and uptime with screaming
                      performance for the workloads that run your enterprise.
                      Growing small businesses to enterprise workloads, Lenovo
                      rack servers offer the unmatched value, flexibility and
                      industry-leading efficiency to meet demands with legendary
                      quality and reliability.
                    </>
                  ) : (
                    <>
                      Lenovo servers deliver powerful performance and versatile
                      storage configurations i for SMBs to large institutions...{" "}
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 3 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 cardThree card3">
              <Card.Img
                variant="top"
                src="./assets/Servers/3.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>HP Servers</Card.Title>
                <Card.Text>
                  {expandedCards["card3"] ? (
                    <>
                      Compute engineered for your hybrid world. Unlock more
                      value from your data and accelerate AI outcomes that
                      deliver insights and innovation
                    </>
                  ) : (
                    <>
                      Compute engineered for your hybrid world. Unlock more
                      value from your data and accelerate AI outcomes...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card3")}
                >
                  {expandedCards["card3"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src="./assets/Servers/1.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>DELL Servers</Card.Title>
                <Card.Text>
                  {expandedCards["card1"] ? (
                    <>
                      Deliver reliable and secure high-performance applications
                      and accelerate compute-intensive workloads inside
                      traditional data centers or in extreme conditions at the
                      edge.
                    </>
                  ) : (
                    "Deliver reliable and secure high-performance applications and accelerate compute-intensive workloads inside... "
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src="./assets/Servers/4.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>ACER Servers</Card.Title>
                <Card.Text>
                  {expandedCards["card1"] ? (
                    <>
                      To ensure the success of your business, the Acer bundles
                      server-class features, expandability and technology in a
                      package that delivers great price performance. This
                      ultra-dense server is a power-efficient solution for
                      medium-sized enterprises.
                    </>
                  ) : (
                    "To ensure the success of your business, the Acer bundles server-class features, expandability and technology in a package that delivers... "
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Servers;
