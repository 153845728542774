import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Gotob from "./components/Gotob/Gotob";
import { Route, Routes } from "react-router-dom";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import Firewall from "./components/Firewall/Firewall";
import Passive_components from "./components/Passive_components/Passive_components";
import Antivirus from "./components/Antivirus/Antivirus";
import Switches from "./components/Switches/Switches";
import Services from "./components/Services/Services";
import Wifidevices from "./components/Wifidevices/Wifidevices";
import Servers from "./components/Servers/Servers"; 
import CloudServices from "./components/CloudServices/CloudServices";
import NetworkStorage from "./components/NetworkStorage/NetworkStorage";
import VideoConferencing from "./components/VideoConferencing/VideoConferencing";
import Ipbax from "./components/Ipbax/Ipbax";
import Surveillance from "./components/Surveillance Cameras/Surveillance";
import Desktop from "./components/Desktop/Desktop";
import Workstation from "./components/Workstation/Workstation";
import Display from "./components/Display/Display";
import Conference_Devices from "./components/Conference Devices/Conference_Devices";
import Printer from "./components/Printer/Printer";
import Accessories from "./components/Accessories/Accessories";


function App() {
  return (
    <div className="App">
      <Gotob />

      <Header />

      <Routes>
        <Route>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/FireWall" element={<Firewall />} />
          <Route path="/Antivirus" element={<Antivirus />} />
          <Route path="/Switches" element={<Switches />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Wifidevices" element={<Wifidevices />} />
          <Route path="/Servers" element={<Servers />} />
          <Route path="/Passive_components" element ={<Passive_components />} />
          <Route path="/CloudServices"element={<CloudServices />} />
          <Route path="/NetworkStorage" element={<NetworkStorage />} />
          <Route path="/VideoConferencing" element={<VideoConferencing />} />
          <Route path="/Ipbax" element={<Ipbax />} />
          <Route path="/Surveillance" element={<Surveillance />} />
          <Route path="/Desktop" element={<Desktop />} />
          <Route path="/Workstation" element={<Workstation />} />
          <Route path="/Display" element={<Display />} />
          <Route path="/Conference_Devices" element={<Conference_Devices />} />
          <Route path="/Printer" element={<Printer />} />
          <Route path="/Accessories" element={<Accessories />} />
        </Route>
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
