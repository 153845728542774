import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Display.css";

const Display = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}
      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>Display: LFD/Commercial Display</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\display\LG.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>LG Display </Card.Title>
                <Card.Text className="cartText">
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                      <p>LG Digital Signage Solution provides a high-performing
                      workplace as a content communication platform which
                      bridges management, employees and consumers with its
                      exceptional display quality, flexible design as well as
                      reliability.</p>

                      <p>Companies can rely on LG Digital Signage for
                      superior display quality, flexible design, and easy-to-use
                      features – solutions from installation to customization,
                      use, and maintenance.</p> 
                      
                      <p>Vivid screens, reliability and easy
                      use enhance the work environment and make remote work
                      effortless. With the help of our products, company members
                      can write, create, edit, and share data more conveniently</p>
                    </>
                  ) : (
                    " LG Digital Signage Solution provides a high-performing workplace as a content communication platform which bridges management..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Display;
