import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Workstation.css";

const Workstation = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}
      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>Workstation</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\WORKSTATION\LENOVO.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>LENOVO Workstation</Card.Title>
                <Card.Text className="cartText">
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                      Whether you need to create complex models, render
                      photorealistic imagery, or analyze geophysical and seismic
                      interpretations. Powerful workstations have been tested
                      and proven to deliver the performance needed to power even
                      the most demanding applications. you can be confident that
                      it will more than meet the demands of your business and
                      your industry.
                    </>
                  ) : (
                    "Whether you need to create complex models, render photorealistic imagery, or analyze geophysical and seismic interpretations. Powerful workstations have been tested..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src=".\assets\WORKSTATION\hp.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>HP Workstation</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card4"] ? (
                    <>
                      {" "}
                      Experience a whole new level of performance for your
                      professional workflow. Workstation is engineered to
                      seamlessly run multi-threaded apps for fast rendering,
                      simulation, and now real-time ray tracing. And when your
                      demands change, you have impressive expandability to
                      upgrade.
                    </>
                  ) : (
                    <>
                      Experience a whole new level of performance for your
                      professional workflow. Workstation is engineered to
                      seamlessly run multi-threaded apps for fast rendering...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card4")}
                >
                  {expandedCards["card4"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 3 */}
          <Col className=" col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5  packageCart card5">
              <Card.Img
                variant="top"
                src=".\assets\WORKSTATION\DELL.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>DELL Workstation</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card5"] ? (
                    <>
                      {" "}
                      Take your ideas to the next level with the world’s #1
                      workstations; optimized for performance, reliability and
                      user experience
                    </>
                  ) : (
                    <>
                      Take your ideas to the next level with the world’s #1
                      workstations; optimized for performance, reliability and
                      user experience
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card5")}
                >
                  {expandedCards["card5"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\WORKSTATION\ACER.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>ACER Workstation</Card.Title>
                <Card.Text className="cartText">
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                      Workstation incorporates enterprise-grade hardware to
                      deliver rock-solid performance and dependability, as well
                      as great flexibility. With power-saving processors and
                      substantial advancements in HDD and memory maximization,
                      Offers a comprehensive solution for all business scenarios
                      and design projects.
                    </>
                  ) : (
                    "Workstation incorporates enterprise-grade hardware to deliver rock-solid performance and dependability, as well as great flexibility. With power-saving processors..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Workstation;
