import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./About.css";

const About = () => {
  return (
    <div>
      {/* About Page */}

      <Container>
        <Row>
          <Col lg={6} className="order-2 order-lg-1">
            <h1 className="aboutwrapper fw-bold ">About Us</h1>
            <p className="aboutparawrapper">
              Our expertise with the latest tools and techniques, and the
              experience of our professional experts has helped us to provide
              100% satisfying results to our customers. We have worked miracles
              across various sectors of web design and development along with
              digital marketing, flaunting our creativity and skills, which has
              bagged us a lot of accolades and awards.
            </p>
            <p>
              S&T Technology Services one of the emerging IT/ITES integrators
              having expertise in the following fields, Networking, Servers,
              Security Storage, Wireless Products, Video Conferencing, Software,
              Desktops, Laptops, Surveillance, Bio Metric systems. Also focused
              on AMC, LMC, FMS Services.
            </p>
            <p>
              We have enthusiastic team to craft creative web site, Mobile
              applications and digital marketing, ensuring that the client
              businesses flourish and create a unique brand experience for their
              dear customers.
            </p>
          </Col>
          {/* Img*/}
          <Col lg={6} className="order-1 order-lg-2 mt-5">
            <div>
              <img
                src="./assets/img_sm_32.jpg"
                alt="bg"
                usemap="#image-map"
                className="glitboximage   img-fluid"
                
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <div>
              <Col>
                <div>
                  <div className="icon1 ">
                    <i class="bi bi-people-fill"></i>
                  </div>
                </div>
              </Col>
              {/* <Col>
                <p className="fs-3 contenthead1 fw-bold ">Experience</p>
                <p className="content1">
                  Started at the year of 2014. Running successfully for the past
                  6+ years with well talented and experienced trainers.
                </p>
                <p className="contenticon1 fs-6 fw-bold">
                  Learn More<i class="bi bi-arrow-right"></i>
                </p>
              </Col> */}
            </div>
          </Col>
          {/* Transport Icon 2 */}
          {/* <Col>
            <Col>
              <div>
                <div className="icon2">
                  <i class="bi bi-highlighter"></i>
                </div>
              </div>
            </Col>
            <Col>
              <p className="fs-3 contenthead2 fw-bold">Education</p>
              <p className="content2">
                All category of age persons can get knowledge on computer and
                Spoken English in simple and creative manner.
              </p>
              <p className="contenticon2 fs-6 fw-bold">
                Learn More<i class="bi bi-arrow-right"></i>
              </p>
            </Col>
          </Col> */}
          {/* Transport Icon 3 */}
          {/* <Col>
            <Col>
              <div>
                <div className="icon3">
                  <i class="bi bi-laptop"></i>
                </div>
              </div>
            </Col>
            <Col>
              <p className="fs-3 contenthead3 fw-bold">Certificate</p>
              <p className="content3">
                Candidates can get National Approved certificates for all
                computer courses and company certificate for Internships.
              </p>
              <p className="contenticon3 fs-6 fw-bold">
                Learn More<i class="bi bi-arrow-right"></i>
              </p>
            </Col>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default About;
