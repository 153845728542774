// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  /* About Content */
  
  .aboutwrapper {
    text-align: justify;
    color: #24146c;
    margin-top: 5%;
  }
  .aboutparawrapper {
    text-align: justify;
  }
  .diagram {
    text-align: justify;
    font-size: 40px;
    color: blue;
  }
  .diagramwrappercontentheading1 {
    text-align: justify;
    margin-left: 51px;
    margin-top: -40px;
  }
  .diagramwrappercontentheadingpara1 {
    text-align: justify;
    margin-left: 50px;
  }
  
  .glitboximage {
    width: 1000px;
    
  }
  .img-fluid{
    
    height: 370px !important;
  }
  
  @media (max-width: 575.98px) {
    .glitboximage {
      width: 800px;
      height: 300px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .glitboximage {
      width: 1000px;
      height: 600px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":";EACE,kBAAkB;;EAElB;IACE,mBAAmB;IACnB,cAAc;IACd,cAAc;EAChB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;IACnB,eAAe;IACf,WAAW;EACb;EACA;IACE,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;EACnB;EACA;IACE,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,aAAa;;EAEf;EACA;;IAEE,wBAAwB;EAC1B;;EAEA;IACE;MACE,YAAY;MACZ,aAAa;IACf;EACF;;EAEA;IACE;MACE,aAAa;MACb,aAAa;IACf;EACF","sourcesContent":["\n  /* About Content */\n  \n  .aboutwrapper {\n    text-align: justify;\n    color: #24146c;\n    margin-top: 5%;\n  }\n  .aboutparawrapper {\n    text-align: justify;\n  }\n  .diagram {\n    text-align: justify;\n    font-size: 40px;\n    color: blue;\n  }\n  .diagramwrappercontentheading1 {\n    text-align: justify;\n    margin-left: 51px;\n    margin-top: -40px;\n  }\n  .diagramwrappercontentheadingpara1 {\n    text-align: justify;\n    margin-left: 50px;\n  }\n  \n  .glitboximage {\n    width: 1000px;\n    \n  }\n  .img-fluid{\n    \n    height: 370px !important;\n  }\n  \n  @media (max-width: 575.98px) {\n    .glitboximage {\n      width: 800px;\n      height: 300px;\n    }\n  }\n  \n  @media (min-width: 992px) and (max-width: 1199.98px) {\n    .glitboximage {\n      width: 1000px;\n      height: 600px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
