import React, { useState } from 'react';
import { Container,Button, Col, Row} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./VideoConferencing.css"

const VideoConferencing = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards(prevState => ({
      ...prevState,
      [cardId]: !prevState[cardId]
    }));
  };
  return (
    <div>
       {/* Card Program */}
        
        
       <Container>
        <Row>
          <Col>
            <div className="service" >
              <p >Our Products</p>
              <h1>Video Conferencing</h1>
            </div>
          </Col>
        </Row>
      </Container>
      {/* card 1 */}
      <Container>
        <Row>
       
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src="./assets/VideoConferencing/1.jpg"
                className="cardImg"
              />
              <Card.Body>
                <Card.Title>POLYCOM Video Conferencing</Card.Title>
                <Card.Text>
                  <h4>World-class security controls</h4>
                  {  expandedCards['card1']  ? (
              <>
                Defending networks against increasingly sophisticated threats requires industry-leading intelligence and consistent protections everywhere.
                Improve your security posture today with Cisco Secure Firewall.
              </>
            ) : (
              'Defending networks against increasingly sophisticated threats...'
            )}
                </Card.Text>
                <Button className="card-btn" variant="primary" onClick={() => handleReadMore('card1')}>
                {expandedCards['card1'] ? 'Show Less' : 'Read More'}
          </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card2">
              <Card.Img
                variant="top"
                src="./assets/VideoConferencing/2.jpg"
                className="cardImg"
              />
              <Card.Body>
                <Card.Title>
                LOGITECH Video Conferencing
                </Card.Title>
                <Card.Text>
                  <h4>World-class security controls</h4>
                  {  expandedCards['card2']  ? (
              <>
                Defending networks against increasingly sophisticated threats requires industry-leading intelligence and consistent protections everywhere.
                Improve your security posture today with Cisco Secure Firewall.
              </>
            ) : (
              'Defending networks against increasingly sophisticated threats...'
            )}
                </Card.Text>
                <Button className="card-btn" variant="primary" onClick={() => handleReadMore('card2')}>
                {expandedCards['card2'] ? 'Show Less' : 'Read More'}
          </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 3 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 cardThree card3">
              <Card.Img
                variant="top"
                src="./assets/VideoConferencing/3.jpg"
                className="cardImg"
              />
              <Card.Body>
                <Card.Title>
                AVAYA Video Conferencing
                </Card.Title>
                <Card.Text>
                  <h4>World-class security controls</h4>
                  {  expandedCards['card3']  ? (
              <>
                Defending networks against increasingly sophisticated threats requires industry-leading intelligence and consistent protections everywhere.
                Improve your security posture today with Cisco Secure Firewall.
              </>
            ) : (
              'Defending networks against increasingly sophisticated threats...'
            )}
                </Card.Text>
                <Button className="card-btn" variant="primary" onClick={() => handleReadMore('card3')}>
                {expandedCards['card3'] ? 'Show Less' : 'Read More'}
          </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src="./assets/VideoConferencing/4.jpg"
                className="cardImg"
              />
              <Card.Body>
                <Card.Title>ALCATEL Video Conferencing</Card.Title>
                <Card.Text>
                  <h4>World-class security controls</h4>
                  {  expandedCards['card4']  ? (
              <>
                Defending networks against increasingly sophisticated threats requires industry-leading intelligence and consistent protections everywhere.
                Improve your security posture today with Cisco Secure Firewall.
              </>
            ) : (
              'Defending networks against increasingly sophisticated threats...'
            )}
                </Card.Text>
                <Button className="card-btn" variant="primary" onClick={() => handleReadMore('card4')}>
                {expandedCards['card4'] ? 'Show Less' : 'Read More'}
          </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className=" col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5  packageCart card5">
              <Card.Img
                variant="top"
                src="./assets/VideoConferencing/5.jpg"
                className="cardImg"
              />
              <Card.Body>
                <Card.Title>YEALINK Video Conferencing
                </Card.Title>
                <Card.Text>
                  <h4>World-class security controls</h4>
                  {  expandedCards['card5']  ? (
              <>
                Defending networks against increasingly sophisticated threats requires industry-leading intelligence and consistent protections everywhere.
                Improve your security posture today with Cisco Secure Firewall.
              </>
            ) : (
              'Defending networks against increasingly sophisticated threats...'
            )}
                </Card.Text>
                <Button className="card-btn" variant="primary" onClick={() => handleReadMore('card5')}>
                {expandedCards['card5'] ? 'Show Less' : 'Read More'}
          </Button>
              </Card.Body>
            </Card>
          </Col>
                 </Row>
      </Container>
    </div>
  )
}

export default VideoConferencing
