import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Firewall.css";

const Firewall = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };

  return (
    <div>
      {/* Card Program */}

      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1 >Firewalls</h1>
            </div>
          </Col>
        </Row>
      </Container>
      {/* card 1 */}
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src="./assets/Firewall/img_sm_14.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>CISCO Firewalls</Card.Title>
                <Card.Text>
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                      Defending networks against increasingly sophisticated
                      threats requires industry-leading intelligence and
                      consistent protections everywhere. Improve your security
                      posture today with Cisco Secure Firewall.
                    </>
                  ) : (
                    "Defending networks against increasingly sophisticated threats requires industry-leading intelligence and..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card2">
              <Card.Img
                variant="top"
                src="./assets/Firewall/img_sm_15.png"
                className="cardImg"
                style={{ height: "250px"}}
              />
              <Card.Body>
                <Card.Title>Check Point</Card.Title>
                <Card.Text>
                  {expandedCards["card2"] ? (
                    <>
                      Check Point gateways provide superior security beyond any
                      Next Generation Firewall (NGFW). These gateways are the
                      best at preventing the cyber attacks with innovative
                      security services.
                    </>
                  ) : (
                    <>
                      Check Point gateways provide superior security beyond any
                      Next Generation Firewall (NGFW). These gateways are the
                      best...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
           {/* card 2 */}
           <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src="./assets/Firewall/img_sm_17.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>Fortinet Network Firewall</Card.Title>
                <Card.Text>
                  {expandedCards["card4"] ? (
                    <>
                      {" "}
                      FortiGate Next-Generation Firewalls deliver the industry’s
                      best ROI, provide AI/ML-powered threat protection, and
                      support the convergence of networking and security.
                    </>
                  ) : (
                    <>
                      FortiGate Next-Generation Firewalls deliver the industry’s
                      best ROI, provide AI/ML-powered threat protection...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card4")}
                >
                  {expandedCards["card4"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Firewall;
