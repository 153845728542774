import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Printer.css";

const Printer = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}
      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>Printer/Plotter</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\printerplotter\hp.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>HP Printer/Plotter</Card.Title>
                <Card.Text className="cartText">
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                      Optimize your office performance with increased IT
                      control, blazing fast print speeds, high-performance, and
                      scanning.
                    </>
                  ) : (
                    " Optimize your office performance with increased IT control, blazing fast print speeds, high-performance, and scanning."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\printerplotter\tvs.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>TVS Printer</Card.Title>
                <Card.Text className="cartText">
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                      TVS product range makes its presence felt across varied
                      commercial categories -through unsurpassed efficiency,
                      proven robustness and a deep understanding of business
                      needs.
                    </>
                  ) : (
                    "TVS product range makes its presence felt across varied commercial categories -through unsurpassed efficiency..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\printerplotter\epson.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>EPSON Printer</Card.Title>
                <Card.Text className="cartText">
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                      Epson business printers deliver amazing quality,
                      blazing-fast speeds, exceptional reliability and
                      dependable performance to meet your business needs.
                    </>
                  ) : (
                    "Epson business printers deliver amazing quality, blazing-fast speeds, exceptional reliability and dependable performance..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Printer;
