import React, { useRef } from "react";
import { Navbar, Nav, Container, Col, Row, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const navigate = useNavigate();
  const navbarCollapseRef = useRef(null);

  const handleNavLinkClick = (path) => {
    navigate(path);
    if (navbarCollapseRef.current) {
      navbarCollapseRef.current.classList.remove("show"); // Collapse the navbar
    }
  };
  return (
    <div className="sticky-top">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container style={{ position: "relative" }}>
          <Navbar.Brand href="#" className="logo">
            <Row>
              <Col>
                <img
                  src="/logo1.jpg"
                  alt="Logo"
                  height="60"
                  className="d-inline-block align-top"
                  style={{ mixBlendMode: "multiply" }}
                />
              </Col>
              <Col>
                <div className="logoHead1">S&T</div>
                <div className="logoHead2">Technologies</div>
              </Col>
            </Row>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" ref={navbarCollapseRef}>
            <Nav className="ms-auto">
              <Nav.Link onClick={() => handleNavLinkClick("/")}>Home</Nav.Link>
              <NavDropdown
                title="IT Services"
                id="basic-nav-dropdown"
                className="overflow "
              >
                <div className="container nested">
                  <div class="row row-cols-1">
                    <div className="col">
                      <Nav.Link
                        onClick={() => handleNavLinkClick("/CloudServices ")}
                      >
                        Web Development
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => handleNavLinkClick("/CloudServices ")}
                      >
                        Mobile App Development
                      </Nav.Link>
                    </div>
                  </div>
                </div>
              </NavDropdown>
              {/* Products --start */}
              <NavDropdown
                title="Products"
                id="basic-nav-dropdown"
                className="overflow"
              >
                <div className="container nested">
                  <div class="row row-cols-1">
                    <div className="col">
                      <Nav.Link
                        onClick={() => handleNavLinkClick("/CloudServices ")}
                      >
                        DMS Application
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => handleNavLinkClick("/CloudServices ")}
                      >
                        Sales and Distribution Management
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => handleNavLinkClick("/CloudServices ")}
                      >
                        Inventory Management
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => handleNavLinkClick("/CloudServices ")}
                      >
                        Order Processing
                      </Nav.Link>
                    </div>
                  </div>
                </div>
              </NavDropdown>
              {/* Products --end */}

              <NavDropdown
                title="Service"
                id="basic-nav-dropdown"
                className="overflow service"
              >
                {/* Nested Dropdown */}
                <div className="container nested">
                  <div class="row row-cols-2">
                    <div className="col">
                      <NavDropdown
                        title="Network Security"
                        id="basic-nav-dropdown-nested"
                        drop="end"
                        className="child"
                      >
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/Firewall")}
                        >
                          Firewall
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/Antivirus")}
                        >
                          Antivirus
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown
                        title="Network Solutions"
                        id="basic-nav-dropdown-nested"
                        drop="end"
                        className="child"
                      >
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/Switches")}
                        >
                          Switches
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/Wifidevices")}
                        >
                          Wifidevices
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() =>
                            handleNavLinkClick("/Passive_components")
                          }
                        >
                          Network Passive Components
                        </NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown
                        title={
                          <>
                            Personal Computing &<br />
                            Enterprise Solution
                          </>
                        }
                        id="basic-nav-dropdown-nested"
                        drop="end"
                        className="child"
                      >
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/Servers")}
                        >
                          Servers
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/Desktop")}
                        >
                          Desktop & Notebook
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/Workstation")}
                        >
                          Workstation
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/Printer")}
                        >
                          Printer & Plotter
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("./Accessories")}
                        >
                          Accessories
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link
                        onClick={() => handleNavLinkClick("/CloudServices ")}
                      >
                        Cloud Services
                      </Nav.Link>
                      <NavDropdown
                        title={
                          <>
                            Security & <br /> Surveillance
                          </>
                        }
                        id="basic-nav-dropdown-nested"
                        drop="end"
                        className="child"
                      >
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/Surveillance")}
                        >
                          Biometrics & Surveillance System
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                    <div className="col">
                      <NavDropdown
                        title={
                          <>
                            Communication &
                            <br />
                            Collaboration
                          </>
                        }
                        id="basic-nav-dropdown-nested"
                        drop="end"
                        className="child"
                      >
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/Display")}
                        >
                          Display
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() =>
                            handleNavLinkClick("/Conference_Devices")
                          }
                        >
                          IP/Conference Devices
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link onClick={() => handleNavLinkClick("/Services")}>
                        Services
                      </Nav.Link>
                      <NavDropdown
                        title="NetworkStorage"
                        drop="end"
                        className="child"
                      >
                        <NavDropdown.Item
                          onClick={() => handleNavLinkClick("/NetworkStorage")}
                        >
                          Network Storage
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </div>
                </div>
              </NavDropdown>
              <Nav.Link onClick={() => handleNavLinkClick("/About")}>
                About us
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick("/Contact")}>
                Contact us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
