import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Passive_components.css";

const Passive_components = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };

  return (
    <div>
      {/* Card Program */}

      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1 >Network Passive components</h1>
            </div>
          </Col>
        </Row>
      </Container>
      {/* card 1 */}
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Passive_components\1.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card2">
              <Card.Img
                variant="top"
                src=".\assets\Passive_components\2.jpg"
                className="cardImg"
                style={{ height: "250px"}}
              />
              
            </Card>
          </Col>
           {/* card 2 */}
           <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src=".\assets\Passive_components\3.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
            
            </Card>
          </Col>
                    {/* card 1 */}
                    <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Passive_components\4.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              
            </Card>
          </Col>
                    {/* card 1 */}
                    <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Passive_components\5.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              
            </Card>
          </Col>
            {/* card 1 */}
            <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Passive_components\6.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              
            </Card>
          </Col>
            {/* card 1 */}
            <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Passive_components\7.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              
            </Card>
          </Col>
        </Row>
      </Container>
      

    </div>
  );
};

export default Passive_components;
