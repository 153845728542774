// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Head Content */

.content {
  margin-top: 50px;
}

.headpara {
  background: rgba(239, 230, 230, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  font-family: 'Poppins', sans-serif;
  color: black; /* Changed the font color to black */
}

.headpara ul,
.headpara li,
.headpara span,
.headpara p,
.headpara a {
  font-size: 0.9rem !important; /* Reduced the font size and added !important */
}

.headbg {
  background-color: #f4f7f8;
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vh - 6rem);
  background-position: top;
}
`, "",{"version":3,"sources":["webpack://./src/components/Contact/Contact.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;EACE,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,gDAAgD;EAChD,6BAA6B;EAC7B,qCAAqC;EACrC,mBAAmB;EACnB,2CAA2C;EAC3C,kCAAkC;EAClC,YAAY,EAAE,oCAAoC;AACpD;;AAEA;;;;;EAKE,4BAA4B,EAAE,+CAA+C;AAC/E;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,4BAA4B;EAC5B,0BAA0B;EAC1B,wBAAwB;AAC1B","sourcesContent":["/* Head Content */\n\n.content {\n  margin-top: 50px;\n}\n\n.headpara {\n  background: rgba(239, 230, 230, 0.3);\n  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\n  backdrop-filter: blur(10.5px);\n  -webkit-backdrop-filter: blur(10.5px);\n  border-radius: 10px;\n  border: 1px solid rgba(255, 255, 255, 0.18);\n  font-family: 'Poppins', sans-serif;\n  color: black; /* Changed the font color to black */\n}\n\n.headpara ul,\n.headpara li,\n.headpara span,\n.headpara p,\n.headpara a {\n  font-size: 0.9rem !important; /* Reduced the font size and added !important */\n}\n\n.headbg {\n  background-color: #f4f7f8;\n  background-size: cover;\n  background-repeat: no-repeat;\n  height: calc(100vh - 6rem);\n  background-position: top;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
