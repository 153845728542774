import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Conference_Devices.css";

const Conference_Devices = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}
      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>IP Conference Devices</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\IP CONFERENCE DEVICE\Avaya.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>AVAYA</Card.Title>
                <Card.Text className="cartText">
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                     Take the first step to innovative workplace communication with a stable communication core, advanced collaboration tools, and best-of-breed integrations that keep your tech stack intact. Whether on-prem, private cloud, or public cloud, your innovation journey starts here. 
                    </>
                  ) : (
                    " Take the first step to innovative workplace communication with a stable communication core, advanced collaboration tools..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
  {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src=".\assets\IP CONFERENCE DEVICE\POLYCOM.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>POLYCOM</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card4"] ? (
                    <>
                      {" "}
                      Whether it’s via audio, video, or content sharing, Poly Trio conference phones give your teams the ability to clearly express and exchange ideas. 
                    </>
                  ) : (
                    <>
                      Whether it’s via audio, video, or content sharing, Poly Trio conference phones give your teams the ability to clearly express and exchange ideas...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card4")}
                >
                  {expandedCards["card4"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
       
        
        </Row>
      </Container>
    </div>
  );
};

export default Conference_Devices;
