import React from "react";
import "./Contact.css";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Contact = () => {
  return (
    <div className="headbg">
      
      <Container>
        <Row>
        <h1 className="aboutwrapper fw-bold ">Contact Us</h1>
          <Col lg={6} className="order-2 order-lg-1 content">
            <div className="headpara">
              <ul>
                <i className="bi bi-geo-alt-fill" />
                <span className="fs-4"> Address</span>
                <li className="fs-5 mb-2">
                  13A, Kumaran Nagar Extn, Kolathur , Chennai - 600099
                </li>
                <i className="bi bi-telephone"></i>
                <span className="fs-4"> Contact Us</span>
                <li className="fs-5 mb-2">
                  <a href="tel:+914447977011">+91 44479 77011</a>
                 
                </li>
                <li className="fs-5 mb-2">
                <a href="tel:+919677037012">+91 96770 37012</a>
                 
                </li>
                <i className="bi bi-envelope"></i>
                <span className="fs-4"> Email</span>
                <li className="fs-5 mb-2">
                  <a href="mailto:marketing@snttechnologies.in">marketing@snttechnologies.in</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col className="gy-4 content">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15542.158983327727!2d80.1994726!3d13.1283177!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x686f4d6689f7e4ed%3A0x4bedc6faf7489a51!2sS%26T%20Technologies!5e0!3m2!1sen!2sin!4v1719642873049!5m2!1sen!2sin"
              width="250"
              height="150"
              allowFullScreen=""
              loading="lazy"
              title="map"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
