import React, { useState } from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  const [show, setShow] = useState({
    networksolution: false,
    networksecurity: false,
    developmentsolution: false,
  });

  const handleShow = (item) => {
    setShow({
      networksolution: item === "networksolution",
      networksecurity: item === "networksecurity",
      developmentsolution: item === "developmentsolution",
    });
  };
  return (
    <div>
      <div className="footer">
        <Container>
          <Row>
            <Col>
              <div className="footOne">
                <h1>S&T</h1>
                <div className="footIcon">
                  <i className="bi bi-twitter me-2 fs-3"></i>
                  <i className="bi bi-facebook me-2 fs-3"></i>
                  <i className="bi bi-instagram me-2 fs-3"></i>
                  <i className="bi bi-linkedin me-2 fs-3"></i>
                </div>
              </div>
            </Col>
            <Col>
              <div className="footTwo">
                <h3>Useful Links</h3>
                <Link to="/">Home</Link>
                <p>
                  <Link to="/about">About Us</Link>
                </p>
                <p>
                  <Link to="/contact">Contact Us</Link>
                </p>
              </div>
            </Col>
            <Col>
              <div className="footThree">
                <h3>Our Services</h3>
                <p className="F-dropdown">
                  <span onClick={() => handleShow("networksecurity")}>
                    Network Security
                  </span>
                  <span
                    className="F-dropdown-links"
                    style={{
                      display: show.networksecurity ? "inline-block" : "none",
                    }}
                  >
                    <ul>
                      <li><Link to='/FireWall'>FireWall</Link></li>
                      <li><Link to='/AntiVirus'>AntiVirus</Link></li>
                    </ul>
                  </span>
                </p>
                <p className="F-dropdown">
                  <span onClick={() => handleShow("networksolution")}>
                    Network Solutions
                  </span>
                  <span
                    className="F-dropdown-links"
                    style={{
                      display: show.networksolution ? "inline-block" : "none",
                    }}
                  >
                    <ul>
                      <li><Link to='/Switches'>Switches</Link></li>
                      <li><Link to='/Wifidevices'>Wifidevices</Link></li>
                      <li><Link to='/Network_Passive_Components'>Network Passive Components</Link></li>
                    </ul>
                  </span>
                </p>
                <p>
                  <Link to="/CloudServices">Cloud Services</Link>
                </p>
                <p className="F-dropdown">
                  <span onClick={() => handleShow("developmentsolution")}>
                    Development Solutions
                  </span>
                  <span
                    className="F-dropdown-links"
                    style={{
                      display: show.developmentsolution ? "inline-block" : "none",
                    }}
                  >
                    <ul>
                      <li><Link to='/Website_Development'>Website Development</Link></li>
                      <li><Link to='/ERP'>ERP</Link></li>
                      <li><Link to='/Payroll'>Payroll</Link></li>
                    </ul>
                  </span>
                </p>
              </div>
            </Col>
            <Col>
              <div className="footFour">
                <h3>Contact Us</h3>
                <p>13A, Kumaran Nagar Extn,</p>
                <p>Kolathur, Chennai - 600099</p>
                <p>
                  Phone: <a href="tel:+914447977011">+91 44 47977 011</a>
                </p>
                <p>
                  Email:{" "}
                  <a href="mailto:marketing@snttechnologies.in">
                    marketing@snttechnologies.in
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <div className="container mt-4">
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>Logis</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">Designed by BootstrapMade</div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
