import React from "react";
import "./Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

// import SidesBar from "../SidesBar/SidesBar";

const Home = () => {
  return (
    <div>
      <Carousel data-bs-theme="dark">
        <Carousel.Item>
          <img
            style={{margin:"auto"}}
            className="d-block"
            src=".\assets\Slide\3.jpg"
            alt="First slide"
            width={1350}
            height={500}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
             style={{margin:"auto"}}
            className="d-block"
            src=".\assets\Slide\6.jpg"
            alt="First slide"
            width={1350}
            height={500}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
             style={{margin:"auto"}}
            className="d-block"
            src=".\assets\Slide\5.jpg"
            alt="First slide"
            width={1350}
            height={500}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
             style={{margin:"auto"}}
            className="d-block"
            src=".\assets\Slide\2.jpg"
            alt="Second slide"
            width={1350}
            height={500}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
           style={{margin:"auto"}}
            className="d-block"
            src=".\assets\Slide\1.jpg"
            alt="Third slide"
            width={1350}
            height={500}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
             style={{margin:"auto"}}
            className="d-block"
            src=".\assets\Slide\4.jpg"
            alt="Third slide"
            width={1350}
            height={500}
          />
        </Carousel.Item>
      </Carousel>

      <Container className="feature">
        <Row>
          <Col lg={6} className="order-1 order-lg-1">
            <div>
              <img
                src="./assets/img_sm_28.jpg"
                alt=""
                className="imgfeature w-75"
              />
            </div>
          </Col>
          <Col lg={6} className="order-2 order-lg-2">
            <h3 className="imgfeaturehead">
                  Vision :
            </h3>
            <p className="imgfeaturepara">
            S&T Technologies is a small business enterprise solution provider for small and large scale business industries.
            Our Company Originally formed in fy 2022. The key focus of S&T is in providing business solution across industries such as IT, ITES, Manufacturing and commitment to customer satisfaction.
            To empower organizations through  innovative IT infrastructure solutions, enabling businesses with emerging technologies and cost effective solution, commitment to customer satisfaction.

            </p>

            
            <h3 className="imgfeaturehead">
                  Mission :
            </h3>
            <p className="imgfeaturepara">
            To deliver reliable, scalable, Secure IT infrastructure, cost effective services that drive business success. And ensuring seamless operations and exceptional customer experience
            </p>
            
            <h3 className="imgfeaturehead">
            Why S&T :
            </h3>
            <p className="imgfeaturepara">
            We at S&T believe in partnership and have built long term relationship and investing cutting edge infrastructure to bring value to your business. We help your people work smarter and collaborate better
            </p>
            <p className="imgfeaturepara">
            We give powerful technologies and tools under flexible approach to make them comfortable and happier and more productive and keep them more engaged.
            </p>
            
          </Col>
        </Row>
      </Container>

      {/* <Container className="mt-5">
     <SidesBar/>
     </Container> */}
    </div>
  );
};

export default Home;
