import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Wifidevices.css";

const Wifidevices = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}
      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>WIFI Devices</h1>
            </div>
          </Col>
        </Row>
      </Container>
      {/* card 1 */}
      <Container>
        <Row>

          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src="./assets/wifidevices/4.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>CISCO WIFI Devices</Card.Title>
                <Card.Text>
                  {expandedCards["card4"] ? (
                    <>
                      {" "}
                      The Cisco wifi devices deliver high density wireless
                      connectivity for offices. Designed with simplicity,
                      flexibility, and security in mind, the Cisco Business
                      Access Points provide superior performance and enhanced
                      range for an optimal user experience.
                    </>
                  ) : (
                    <>
                      The Cisco wifi devices deliver high density wireless
                      connectivity for offices. Designed with simplicity,
                      flexibility...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card4")}
                >
                  {expandedCards["card4"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
               {/* card 3 */}

               <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card3">
            <Card.Img
                variant="top"
                src="./assets/wifidevices/7.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>D-links WIFI Devices</Card.Title>
                <Card.Text>
                  {expandedCards["card3"] ? (
                    <>
                      {" "}
                      Upgrades your network to the latest highspeed wireless
                      technology to bring you lightning-fast Wi-Fi speeds
                      Upgrades your network to the latest highspeed wireless
                      technology to bring you lightning-fast Wi-Fi speeds
                    </>
                  ) : (
                    <>
                      Upgrades your network to the latest highspeed wireless
                      technology to bring you lightning-fast Wi-Fi speeds...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card3")}
                >
                  {expandedCards["card3"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
                    {/* card 1 */}
                    <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src="./assets/wifidevices/1.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>ARUBA WIFI Devices</Card.Title>
                <Card.Text>
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                      HPE Aruba Networking continues its innovation with new
                      Wi-Fi APs that exceed the requirements of the standard.
                      Boost IT, user, and IoT experiences with enterprise
                      connectivity that’s intelligent, fast, and secure.
                    </>
                  ) : (
                    "HPE Aruba Networking continues its innovation with new Wi-Fi APs that exceed the requirements of the standard."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
                    {/* card 1 */}
                    <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src="./assets\wifidevices\9.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>UBIQUITI Wireless Devices</Card.Title>
                <Card.Text>
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                     Unifi Offer enterprise quality hardware performance in a variety of elegantly designed form factors for a wide range of applications in any professional environment.
                    </>
                  ) : (
                    "Unifi Offer enterprise quality hardware performance in a variety of elegantly designed form factors..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          
           
       
        </Row>
      </Container>
    </div>
  );
};

export default Wifidevices;
