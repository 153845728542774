import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./CloudServices.css";

const CloudServices = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}

      <Container>
        <Row>
          <Col>
            <div className="Cloud Services">
              <p>Our Products</p>
              <h1>Cloud Services</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\CloudServices\aws.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>AWS</Card.Title>
                <Card.Text>
                  {expandedCards["card1"] ? (
                    <>
                      Amazon Web Services (AWS) is the world’s most
                      comprehensive and broadly adopted cloud, offering over 200
                      fully featured services from data centres globally.
                      Millions of customers including the fastest-growing
                      startups, largest enterprises, and leading government
                      agencies are using AWS to lower costs, become more agile,
                      and innovate faster.
                    </>
                  ) : (
                    "Amazon Web Services (AWS) is the world’s most comprehensive and broadly adopted cloud, offering over 200 fully featured services..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card2">
              <Card.Img
                variant="top"
                src=".\assets\CloudServices\azure.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>AZURE</Card.Title>
                <Card.Text>
                  {expandedCards["card2"] ? (
                    <>
                      The Azure cloud platform is more than 200 products and
                      cloud services designed to help you bring new solutions to
                      life—to solve today’s challenges and create the future.
                      Build, run, and manage applications across multiple
                      clouds, on-premises, and at the edge, with the tools and
                      frameworks of your choice
                    </>
                  ) : (
                    " The Azure cloud platform is more than 200 products and cloud services designed to help you bring new solutions to life—to solve today’s challenges..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card2">
              <Card.Img
                variant="top"
                src=".\assets\CloudServices\yotta.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>YOTTA</Card.Title>
                <Card.Text>
                  {expandedCards["card2"] ? (
                    <>
                      <p>
                        Yotta's Compute as a Service is an infrastructure as a
                        service (IaaS) that allows businesses and customers to
                        access virtualised machines from Yotta's Uptime
                        Institute certified TIER IV data center. This service
                        further provides a wide selection of instance types,
                        which is optimised to fit different workloads and use
                        cases.
                      </p>

                      <p>
                        Storage as Services : Offer a flexible, scalable, and
                        reliable storage environment based on various storage
                        technologies like file, block and object with guaranteed
                        SLAs.
                      </p>

                      <p>
                        SaaS.Migrate your data and workloads to cloud with
                        Yotta’s software as a service offering. Do away with the
                        hassles and burden of maintaining on-premise hardware.
                        Yotta SaaS comes with the pay-as-you-go model, giving
                        you visibility and control over your IT
                      </p>
                    </>
                  ) : (
                    "Yotta's Compute as a Service is an infrastructure as a service (IaaS) that allows businesses and customers to access virtualised machines..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CloudServices;
