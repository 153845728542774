import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Accessories.css";

const Accessories = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}
      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>Accessories</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Accessories\HP.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>HP Accessories</Card.Title>
                <Card.Text className="cartText">
                  {/* <h4>World-class security controls</h4> */}
                  {expandedCards["card1"] ? (
                    <>
                      Elevate your computing experience with PC peripherals,
                      exceptional mice & keyboards, and stay powered anywhere
                      with reliable batteries and chargers. Experience
                      accessories in modern, durable designs that withstand
                      diverse usage, ensuring a reliable and lasting investment
                    </>
                  ) : (
                    "  Elevate your computing experience with PC peripherals, exceptional mice & keyboards, and stay powered anywhere with reliable batteries and chargers..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src=".\assets\Accessories\LOGITECH.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>LOGITECH Accessories</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card4"] ? (
                    <>
                      {" "}
                      Logitech provides award-winning, beautifully designed
                      peripherals for everyone. Improve performance, comfort,
                      and style while decluttering your desk, home, and office.
                    </>
                  ) : (
                    <>
                      Logitech provides award-winning, beautifully designed
                      peripherals for everyone. Improve performance, comfort,
                      and style while decluttering your desk, home, and office.
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Accessories\ACER.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>ACER Accessories</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card3"] ? (
                    <>
                      Extending the functionality and connectivity of your
                      device in innovative and inventive ways, our accessories
                      help you make full use of it.
                    </>
                  ) : (
                    "  Extending the functionality and connectivity of your device in innovative and inventive ways, our accessories help you make... "
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Accessories\ZEBRONICS.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>ZEBRONICS Accessories</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card3"] ? (
                    <>
                      Zebronics focuses on product design excellence, strict
                      quality controls and a very reliable product to bring
                      home, putting this approach together as the EQR Mantra
                      (Excellence, Quality and Reliability) which has lead to
                      becoming the No.1 brand in IT peripherals and one of the
                      most important player in Multimedia Speakers, LED TVs and
                      Surveillance.
                    </>
                  ) : (
                    "  Zebronics focuses on product design excellence, strict quality controls and a very reliable product to bring home, putting this approach together as the EQR Mantra... "
                  )}
                </Card.Text>
                <Button className="card-btn"
                  variant="primary"
                  
                  onClick={() => handleReadMore("card2")}
                >
                  {expandedCards["card2"] ? "Show Less" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Accessories;
