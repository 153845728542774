import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Desktop.css";

const Desktop = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleReadMore = (cardId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [cardId]: !prevState[cardId],
    }));
  };
  return (
    <div>
      {/* Card Program */}
      <Container>
        <Row>
          <Col>
            <div className="service ">
              <p>Our Products</p>
              <h1>Desktop</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Desktop\dell.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>DELL Desktop</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card1"] ? (
                    <>
                      Take your ideas to the next level with *the world's #1
                      workstations; optimized for performance, reliability and
                      user experience.
                    </>
                  ) : (
                    " Take your ideas to the next level with *the world's #1 workstations; optimized for performance, reliability and user experience. "
                  )}
                </Card.Text>
                <Button className="card-btn"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src=".\assets\Desktop\hp.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>HP Desktop</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card4"] ? (
                    <>
                      {" "}
                      At HP, empower your business with customised desktop
                      solutions. Specify the specs you need and elevate your
                      operations with personalised devices.
                    </>
                  ) : (
                    <>
                      At HP, empower your business with customised desktop
                      solutions. Specify the specs you need and elevate your
                      operations with personalised devices.
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  onClick={() => handleReadMore("card4")}
                >
                  {expandedCards["card4"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 3 */}
          <Col className=" col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5  packageCart card5">
              <Card.Img
                variant="top"
                src=".\assets\Desktop\LENOVO.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>LENOVO Desktop</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card5"] ? (
                    <>
                      {" "}
                      Compact in size yet big on performance, along with all our
                      PC solutions powered by the built-for-business Intel vPro®
                      platform, is designed with the planet in mind.
                    </>
                  ) : (
                    <>
                      Compact in size yet big on performance, along with all our
                      PC solutions powered by the built-for-business Intel vPro®
                      platform...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  onClick={() => handleReadMore("card5")}
                >
                  {expandedCards["card5"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Desktop\ACER.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>ACER Desktop</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card1"] ? (
                    <>
                      Highly expandable business desktops that can be customized
                      to fit your needs. Combining next-gen hardware, intuitive
                      designs, and highly functional applications, you’ll be
                      able to get more done in less time than ever before with
                      Acer business PCs. Choose your perfect business PC on
                      Windows or Chrome with wide range of platforms powered by
                      Intel and AMD processors.
                    </>
                  ) : (
                    "Highly expandable business desktops that can be customized to fit your needs. Combining next-gen hardware, intuitive designs, and highly functional applications..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <div className="service ">
            <h1>Notebooks</h1>
          </div>
          {/* card 1 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Notebooks\lenovo.png"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>LENOVO</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card1"] ? (
                    <>
                      Power-packed, versatile, quality and ultraportable, these
                      devices adapt to any work style. for business. With
                      enhanced security features & tested against extreme
                      conditions.
                    </>
                  ) : (
                    " Power-packed, versatile, quality and ultraportable, these devices adapt to any work style..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 2 */}
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 truckingCart card4">
              <Card.Img
                variant="top"
                src=".\assets\Notebooks\hp.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>HP Notebook</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card4"] ? (
                    <>
                      {" "}
                      Devices that maximise business value to enterprise
                      employees across all hybrid workstyles.
                    </>
                  ) : (
                    <>
                      Devices that maximise business value to enterprise
                      employees...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  onClick={() => handleReadMore("card4")}
                >
                  {expandedCards["card4"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* card 3 */}
          <Col className=" col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5  packageCart card5">
              <Card.Img
                variant="top"
                src=".\assets\Notebooks\dell.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>DELL Laptop</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card5"] ? (
                    <>
                      {" "}
                      Power your small business with laptops built to deliver
                      security, performance and enhanced video conferencing.
                    </>
                  ) : (
                    <>
                      Power your small business with laptops built to deliver
                      security...
                    </>
                  )}
                </Card.Text>
                <Button className="card-btn"
                  onClick={() => handleReadMore("card5")}
                >
                  {expandedCards["card5"] ? "showless" : "Read More"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm-8 col-md-6 col-lg-4">
            <Card className="mb-5 card card1 ">
              <Card.Img
                variant="top"
                src=".\assets\Notebooks\ACER.jpg"
                className="cardImg"
                style={{ height: "250px" }}
              />
              <Card.Body>
                <Card.Title>ACER</Card.Title>
                <Card.Text className="cartText">
                  {expandedCards["card1"] ? (
                    <>
                      Optimize resources and save your IT team from undue
                      hassles with highly efficient applications to deploy new
                      devices, secure them, enable remote access, and more
                    </>
                  ) : (
                    " Optimize resources and save your IT team from undue hassles with highly efficient applications to deploy..."
                  )}
                </Card.Text>
                <Button className="card-btn"
                  onClick={() => handleReadMore("card1")}
                >
                  {expandedCards["card1"] ? "Show Less" : "Read More"}{" "}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Desktop;
