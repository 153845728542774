// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footOne {
  text-align: justify;
}
.footTwo {
  text-align: justify;
}
.footThree {
  text-align: justify;
}
.footFour {
  text-align: justify;
}
.footer {
  font-size: 14px;
  background: white;
  /* padding: 50px 0px; */
}

.F-dropdown{
  position: relative;
  width: max-content;
  cursor: pointer;
}

.F-dropdown-links ul{
  position: absolute;
  top: 0px;
  list-style: none;
  display: inline-block;
}

.F-dropdown-links ul li{
  padding: 5px;
  cursor: pointer;
}


@media (max-width: 575.98px) {
  .footOne {
    text-align: justify;
    width: 340px;
    margin-left: -29px;
  }
  .footTwo {
    text-align: justify;
    margin-left: -19px;
  }
  .footFour {
    text-align: center;
  }

  .footer {
    font-size: 14px;
    background-color: #29166f;
    padding: 34px;
    color: azure;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;;AAGA;EACE;IACE,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,YAAY;EACd;AACF","sourcesContent":[".footOne {\n  text-align: justify;\n}\n.footTwo {\n  text-align: justify;\n}\n.footThree {\n  text-align: justify;\n}\n.footFour {\n  text-align: justify;\n}\n.footer {\n  font-size: 14px;\n  background: white;\n  /* padding: 50px 0px; */\n}\n\n.F-dropdown{\n  position: relative;\n  width: max-content;\n  cursor: pointer;\n}\n\n.F-dropdown-links ul{\n  position: absolute;\n  top: 0px;\n  list-style: none;\n  display: inline-block;\n}\n\n.F-dropdown-links ul li{\n  padding: 5px;\n  cursor: pointer;\n}\n\n\n@media (max-width: 575.98px) {\n  .footOne {\n    text-align: justify;\n    width: 340px;\n    margin-left: -29px;\n  }\n  .footTwo {\n    text-align: justify;\n    margin-left: -19px;\n  }\n  .footFour {\n    text-align: center;\n  }\n\n  .footer {\n    font-size: 14px;\n    background-color: #29166f;\n    padding: 34px;\n    color: azure;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
