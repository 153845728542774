// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  /* Cart */
  
  .cardImg {
    overflow: hidden;
  }
  
  .cardImg:hover {
    transition: ease-in 0.2s;
    left: 0;
    right: 0;
    top: 32px;
    bottom: 0;
    transform: scale(1.1);
  }
  
  @media (max-width: 575.98px) {
    .card {
      width: 308px;
    }
    .card1 {
      margin-left: 31px;
    }
    .card2 {
      margin-left: 31px;
    }
    .card3 {
      margin-left: 31px;
    }
    .card4 {
      margin-left: 31px;
    }
    .card5 {
      margin-left: 31px;
    }
    .card6 {
      margin-left: 31px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .packageCart {
      margin-top: -436px;
    }
  
    .wareHouseCard {
      position: absolute;
      width: 300px;
      right: 55px;
      top: 3750px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .card {
      width: 300px;
    }
    .glitboximage {
      width: 1000px;
      height: 600px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Passive_components/Passive_components.css"],"names":[],"mappings":";EACE,SAAS;;EAET;IACE,gBAAgB;EAClB;;EAEA;IACE,wBAAwB;IACxB,OAAO;IACP,QAAQ;IACR,SAAS;IACT,SAAS;IACT,qBAAqB;EACvB;;EAEA;IACE;MACE,YAAY;IACd;IACA;MACE,iBAAiB;IACnB;IACA;MACE,iBAAiB;IACnB;IACA;MACE,iBAAiB;IACnB;IACA;MACE,iBAAiB;IACnB;IACA;MACE,iBAAiB;IACnB;IACA;MACE,iBAAiB;IACnB;EACF;;EAEA;IACE;MACE,kBAAkB;IACpB;;IAEA;MACE,kBAAkB;MAClB,YAAY;MACZ,WAAW;MACX,WAAW;IACb;EACF;;EAEA;IACE;MACE,YAAY;IACd;IACA;MACE,aAAa;MACb,aAAa;IACf;EACF","sourcesContent":["\n  /* Cart */\n  \n  .cardImg {\n    overflow: hidden;\n  }\n  \n  .cardImg:hover {\n    transition: ease-in 0.2s;\n    left: 0;\n    right: 0;\n    top: 32px;\n    bottom: 0;\n    transform: scale(1.1);\n  }\n  \n  @media (max-width: 575.98px) {\n    .card {\n      width: 308px;\n    }\n    .card1 {\n      margin-left: 31px;\n    }\n    .card2 {\n      margin-left: 31px;\n    }\n    .card3 {\n      margin-left: 31px;\n    }\n    .card4 {\n      margin-left: 31px;\n    }\n    .card5 {\n      margin-left: 31px;\n    }\n    .card6 {\n      margin-left: 31px;\n    }\n  }\n  \n  @media (min-width: 768px) and (max-width: 991.98px) {\n    .packageCart {\n      margin-top: -436px;\n    }\n  \n    .wareHouseCard {\n      position: absolute;\n      width: 300px;\n      right: 55px;\n      top: 3750px;\n    }\n  }\n  \n  @media (min-width: 992px) and (max-width: 1199.98px) {\n    .card {\n      width: 300px;\n    }\n    .glitboximage {\n      width: 1000px;\n      height: 600px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
